<template>
	<v-autocomplete
		v-model="model"
		:items="items"
		:rules="responsibleRules"
		:label="label"
		item-text="fullName"
		item-value="id"
		@update:search-input="
			(v) => {
				listenSearch(v);
			}
		"
		@change="
			(v) => {
				listenInput(v);
			}
		"
		hide-no-data
		return-object
    :filter="customFilter"
		required
	></v-autocomplete>
</template>
<script>
export default {
	name: 'SearchInput',
	props: ['value', 'label'],
	data: () => ({
		model: null,
    previousSearchValue: '',
		items: [],
		timeout: null,
		responsibleRules: [(v) => !!v || 'Campo requerido'],
	}),
	watch: {
		value(val) {
			if (val && val?.id && val?.fullName) {
				this.model = val?.id;
				this.items.push(val);
			} else {
				this.model = null;
				this.items = [];
			}
		},
	},
	methods: {
		// listenSearch(value) {
		//   clearTimeout(this.timeout);
		//   if ((value && value?.length > 1) || value === "") {
		//     this.timeout = setTimeout(() => {
		//       this.$store
		//         .dispatch("users/getUsers", {
		//           search: value,
		//         })
		//         .then(() => {
		//           this.items = this.$store.state.users.users;
		//         });
		//     }, 800);
		//   }
		// },

		listenSearch(value) {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				const searchValue = value || '';
				const trimmedSearchValue = searchValue.trim();

				if (searchValue === '') {
					this.$store
						.dispatch('users/searchGetUsers', {
							search: '',
							active: true,
							searchType: 'select',
							pagination: false,
						})
						.then(() => {
							this.items = this.$store.state.users.users;
						});

					this.model = null;
					this.previousSearchValue = '';
					return;
				}

				if (trimmedSearchValue === '') {
					return;
				}

				if (this.previousSearchValue !== trimmedSearchValue) {
					this.$store
						.dispatch('users/searchGetUsers', {
							search: trimmedSearchValue,
							active: true,
							searchType: 'select',
							pagination: false,
						})
						.then(() => {
							this.items = this.$store.state.users.users;
						});

					this.previousSearchValue = trimmedSearchValue;
				}
			}, 800);
		},

		listenInput(value) {
			if (value && value?.id && value?.fullName) {
				this.$emit('input', { id: value?.id, fullName: value?.fullName });
			}
		},

    customFilter(item, queryText, itemText) {
			const searchTerms = queryText.toLowerCase().split(' ');
			const fullName = itemText.toLowerCase();

			return searchTerms.every(term => fullName.includes(term));
		},
	},
	destroyed() {
		console.log('destroy!!');
	},
};
</script>
