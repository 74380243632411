<template>
  <v-text-field
    @input="
      (val) => {
        listenSearch(val);
      }
    "
    append-icon="mdi-magnify"
    label="Buscar"
    class="mx-4 pt-6"
    :value="value"
  ></v-text-field>
</template>
<script>
export default {
  name: "SearchInput",
  props: ["value", "typeDispatch"],
  data: () => ({
    timeout: null,
  }),
  methods: {
    listenSearch(value) {
      this.$emit("input", value);
      clearTimeout(this.timeout);
      if (value.length > 1 || value == "") {
        this.timeout = setTimeout(() => {
          this.$store.dispatch(this.typeDispatch, {
            page: 1,
            search: value,
          });
        }, 800);
      }
    },
  },
};
</script>
