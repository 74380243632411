<template>
  <v-dialog v-model="dialogDelete" max-width="500px">
    <v-card>
      <v-card-title class="text-body-1 primary--text justify-center">
        ¿Estás seguro de que quieres eliminar?
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="deleteItemConfirm()">SI</v-btn>
        <v-btn color="blue darken-1" text @click="closeDelete()">No</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { $EventBus } from "@/main";
export default {
  name: "DeleteManagementDialog",
  props: {
    management: Object,
  },
  data: () => ({
    dialogDelete: false,
  }),
  watch: {
    management(val) {
      if (val && val.id) {
        this.dialogDelete = true;
      }
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    async deleteItemConfirm() {
      try {
        await this.$store.dispatch(
          "managements/deleteManagement",
          this.management.id
        );
        $EventBus.$emit("showSnack", {
          color: "green",
          text: "Gerencia eliminada",
        });
        this.$emit("resetFilter");
      } catch (error) {
        $EventBus.$emit("showSnack", {
          color: "red",
          text: error,
        });
      }
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
    },
  },
};
</script>
