import CreateManagementDialog from "@/components/companies/management/CreateManagementDialog.vue";
import EditManagementDialog from "@/components/companies/management/EditManagementDialog.vue";
import DeleteManagementDialog from "@/components/companies/management/DeleteManagementDialog.vue";
import SearchInput from "@/components/global/SearchInput.vue";

export default {
  name: "Managements",
  data: () => ({
    headers: [
      {
        text: "Empresa",
        value: "company_name",
        align: "start",
        sortable: false,
        class: "primary--text",
      },
      {
        text: "Nombre",
        value: "name",
        sortable: false,
        class: "primary--text",
      },
      {
        text: "Responsable",
        value: "responsible_id.fullName",
        sortable: false,
        class: "primary--text",
      },
      {
        text: "Estado",
        value: "active",
        align: "center",
        sortable: false,
        class: "primary--text",
      },
      {
        text: "Acciones",
        value: "actions",
        align: "right",
        sortable: false,
        class: "primary--text",
      },
    ],
    search: "",
    editedItem: {},
    deletedItem: {},
    currentPage: 1,
    companySelected: null,
    limit: 5,
    rowsPerPage: [{ value: 5 }, { value: 10 }, { value: 15 }, { value: 20 }, { value: 25 }, { value: 30 }]
  }),

  computed: {
    companies() {
      return this.$store.getters["companies/getCompaniesList"];
    },
    managements() {
      return this.$store.getters["managements/getManagements"];
    },
    paginationPages() {
      return this.$store.getters["managements/getPaginationPages"];
    },
    isLoading() {
      return this.$store.state.managements.isLoading;
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$store.dispatch("managements/getManagements", { page: 1, limit:this.limit });
      this.$store.dispatch("companies/getCompaniesList");
      this.$store.dispatch("managements/updateCurrentPage", 1);
    },

    editItem(item) {
      this.editedItem = { ...item };
    },

    deleteItem(item) {
      this.deletedItem = { ...item };
    },

    listenCompany() {
      this.currentPage = 1;
      this.search = "";
      this.$store.dispatch("managements/getManagements", {
        page: 1,
        limit: this.limit,
        company_id: this.companySelected,
      });
    },

    listenPagination() {
      this.$store.dispatch("managements/getManagements", {
        page: this.currentPage,
        company_id: this.companySelected,
        search: this.search,
        limit: this.limit
      });
      this.$store.dispatch("managements/updateCurrentPage", this.currentPage);
    },

    resetFilter() {
      this.search = "";
      this.companySelected = null;
      this.currentPage = 1;
      this.limit = 5
    },
  },
  components: {
    CreateManagementDialog,
    EditManagementDialog,
    DeleteManagementDialog,
    SearchInput,
  },
};
