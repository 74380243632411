<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        class="my-1 text-none"
        v-bind="attrs"
        v-on="on"
        rounded
      >
        <v-icon left> mdi-plus </v-icon>
        Nueva Gerencia
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-body-1 primary--text">Nueva Gerencia</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <validation-alert :validationErrors="validationErrors" />
          <v-form ref="form" v-model="isValidFormDialog" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="editedItem.company_id"
                  :items="companies"
                  item-text="name"
                  item-value="id"
                  :rules="companyRules"
                  label="Empresa"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.name"
                  :rules="nameRules"
                  label="Nombre"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <search-user v-model="responsible" label="Responsable" />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="close()"> Cancelar </v-btn>
        <v-btn
          color="blue"
          :loading="isLoading"
          :disabled="isLoading"
          @click="save()"
          text
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ValidationAlert from "@/components/global/ValidationAlert.vue";
import SearchUser from "@/components/global/SearchUser.vue";
import { $EventBus } from "@/main";
export default {
  name: "CreateManagementDialog",
  props: {},

  data: () => ({
    dialog: false,
    isLoading: false,
    isValidFormDialog: false,
    nameRules: [(v) => !!v || "Campo requerido"],
    companyRules: [(v) => !!v || "Campo requerido"],
    responsibleRules: [(v) => !!v || "Campo requerido"],
    responsible: {},
    editedItem: {
      name: "",
      company_id: "",
    },
    defaultItem: {
      name: "",
      company_id: "",
    },
  }),

  computed: {
    companies() {
      return this.$store.getters["companies/getCompanies"];
    },
    validationErrors() {
      return this.$store.getters["managements/getValidationErrors"];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
      if (val) {
        this.$store.dispatch("companies/getCompanies");
      }
    },
  },

  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.responsible = {};
        this.$store.dispatch("managements/cleanValidationErrors");
        this.$refs.form.resetValidation();
      });
    },

    showSnack(color = "", text = "") {
      $EventBus.$emit("showSnack", { color, text });
    },

    async save() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        let data = this.editedItem;
        data.responsible_id = this.responsible?.id;
        data.responsible_name = this.responsible?.fullName;
        try {
          await this.$store.dispatch("managements/saveManagement", data);
          this.showSnack("green", "Se ha registrado satisfactoriamente");
          this.$emit("resetFilter");
          this.close();
        } catch (error) {}
        this.isLoading = false;
      }
    },
  },
  components: {
    ValidationAlert,
    SearchUser,
  },
};
</script>
