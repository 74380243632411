<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card class="">
      <v-card-title>
        <v-progress-linear
          :active="isLoadingUser"
          :indeterminate="isLoadingUser"
          absolute
          top
        ></v-progress-linear>
        <span class="text-body-1 primary--text">Editar Gerencia</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <validation-alert :validationErrors="validationErrors" />
          <v-form ref="form" v-model="isValidFormDialog" lazy-validation>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  v-model="editedItem.company_id"
                  :items="companies"
                  item-text="name"
                  item-value="id"
                  :rules="companyRules"
                  label="Empresa"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="editedItem.name"
                  :rules="nameRules"
                  label="Nombre"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <search-user v-model="responsible" label="Responsable" />
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-select
                v-model="editedItem.management_type"
                label="Seleccionar tipo de gerencia"
                :items="monthValues"
                item-value="value"
                item-text="name"
                hide-details
                dense
              ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-switch v-model="editedItem.active" label="Estado"></v-switch>
              </v-col>
             
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="close()"> Cancelar </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="save()"
          :loading="isLoading"
          :disabled="isLoading"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ValidationAlert from "@/components/global/ValidationAlert.vue";
import SearchUser from "@/components/global/SearchUser.vue";
import { $EventBus } from "@/main";
export default {
  name: "EditManagementDialog",

  props: {
    managementEdit: Object,
  },

  data: () => ({
    dialog: false,
    isLoading: false,
    isLoadingUser: false,
    isValidFormDialog: false,
    nameRules: [(v) => !!v || "Campo requerido"],
    companyRules: [(v) => !!v || "Campo requerido"],
    responsibleRules: [(v) => !!v || "Campo requerido"],
    responsible: { id: null, fullName: null },
    editedItem: {
      id: "",
      name: "",
      company_id: "",
      responsible_id: "",
      responsible_name: "",
      management_type: "",
      active: true,
    },
    defaultItem: {
      id: "",
      name: "",
      company_id: "",
      responsible_id: "",
      responsible_name: "",
      management_type: "",
      active: true,
    },
    monthValues: [{ name: "AREA", value: "area" },{ name:"GERENCIA",value:"management"}]
  }),

  computed: {
    companies() {
      return this.$store.getters["companies/getCompanies"];
    },
    validationErrors() {
      return this.$store.getters["managements/getValidationErrors"];
    },
  },

  watch: {
    managementEdit(val) {
      if (val && val?.id) {
        this.dialog = true;
        this.initialize();
      }
    },
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    async initialize() {
      try {
        this.isLoadingUser = true;
        this.$store.dispatch("companies/getCompanies");
        await this.$store.dispatch(
          "managements/getManagement",
          this.managementEdit?.id
        );
        this.editedItem = await this.$store.getters[
          "managements/getManagement"
        ];
        this.responsible = {
          id: this.editedItem?.responsible_id,
          fullName: this.editedItem?.responsible_name,
        };
      } finally {
        this.isLoadingUser = false;
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.responsible = {};
        this.$store.dispatch("managements/cleanValidationErrors");
        this.$refs.form.resetValidation();
      });
    },

    showSnack(color = "green", text = "") {
      $EventBus.$emit("showSnack", { color, text });
    },

    async save() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        try {
          let data = this.editedItem;
          data.responsible_id = this.responsible.id;
          data.responsible_name = this.responsible.fullName;

          await this.$store.dispatch("managements/updateManagement", data);
          this.showSnack("green", "Gerencia actualizada");
          this.$emit("resetFilter");
          this.close();
        } catch (error) {}
        this.isLoading = false;
      }
    },
  },

  components: {
    ValidationAlert,
    SearchUser,
  },
};
</script>
